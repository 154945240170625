import { API_URL } from 'utils/env';

export function urlForUpload(upload) {
  const url = `/1/uploads/${upload.id || upload}/image`;
  return new URL(url, API_URL).toString();
}

export function urlForFoursquare(photo) {
  return photo.prefix + 'original' + photo.suffix;
}

export function urlForTripAdvisor(photo) {
  return ['original', 'large', 'medium', 'small'].reduce((acc, size) => {
    if (acc) return acc;
    return photo.images[size]?.url;
  }, null);
}
